import React, { Component, useState } from 'react';
import { Row, Col, FormLabel, Button } from 'react-bootstrap';
import { LocalForm, Control, Errors } from 'react-redux-form';
import PagesHeader from './PagesHeaderComponent';
import MapChart from './MapsComponent';
import ReactTooltip from 'react-tooltip';
// import axios from 'axios';

const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const isNumber = (val) => !isNaN(Number(val));
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

function Map() {
    const [content, setContent] = useState("");

    return (
        <>
            <MapChart setTooltipContent={setContent}/>
            <ReactTooltip>{content}</ReactTooltip>
        </>
    );
}

class Contact extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            fname: "", 
            lname: "", 
            tel: "", 
            email: "", 
            message: ""
        };
    }

    handleSubmit = async () => {
        try {
            let res = await fetch('https://thecqtech.com/api/contacts', {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    fname: this.state.fname,
                    lname: this.state.lname,    
                    tel : this.state.tel,
                    email: this.state.email,
                    message: this.state.message
                }),
            });
            let resJson = await res.json();

            if (res.status === 200) {
                this.resetForm();
                alert("Your message has been sent!");
            }
            else { 
                alert("Unable to send message. Some error occured");
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    
    resetForm() {
        this.setState({fname: '', lname: '', tel: '', email: '', message: ''})
    }

    render () {
        return(
            <>
                <PagesHeader/>
                <div className="container" id="offices">
                    <div className="row row-content">
                        <h2 className="text-center mt-5">Operational Presence</h2>
                            {/* <h5 className="text-center mt-3" style={{color: "#092a49"}}>Hover over a location for details</h5> */}
                        <div className="col-10 ml-5">
                            <Map />
                        </div>
                    </div>
                </div>
                <div className="container ml-0 mr-0 pr-0 pl-0" style={{maxWidth: "100%"}}>
                    <div className="row row-content mt-5 justify-content-center" style={{backgroundColor: "#F8F8F8", maxWidth: "100%", marginLeft: "0px", marginRight: "0px"}}>
                        <h2 className="text-center mt-5 mb-3"> Contact Us </h2>
                        {/* <h5 className="text-center mb-5">Email us on info@thecqtech.com</h5> */}
                        <div className="col-12 col-md-4">
                        <LocalForm method="POST" 
                        onSubmit={this.handleSubmit.bind(this)}>
                            <Row className="form-group">
                                <Col>
                                    <FormLabel htmlFor="fname">First Name</FormLabel>
                                    <Control.text model=".fname" id="fname" 
                                    validators={{
                                        required, minLength: minLength(3), maxLength: maxLength(15)
                                    }} 
                                    value={this.state.fname} className="form-control" 
                                    onChange={(e) => this.setState({ fname: e.target.value })}/>
                                    <Errors
                                    className="text-danger"
                                    model=".fname"
                                    show="touched"
                                    messages={{
                                        required: 'Required ',
                                        minLength: 'Must be greater than 2 characters ',
                                        maxLength: 'Must be 15 characters or less '
                                    }}
                                />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col>
                                    <FormLabel htmlFor="lname">Last Name</FormLabel>
                                    <Control.text model=".lname" id="lname" className="form-control"
                                    validators={{
                                        minLength: minLength(3), maxLength: maxLength(15)
                                    }} 
                                    value={this.state.lname} 
                                    onChange={(e) => this.setState({lname: e.target.value})}/>
                                    <Errors
                                    className="text-danger"
                                    model=".lname"
                                    show="touched"
                                    messages={{
                                        minLength: 'Must be greater than 2 characters ',
                                        maxLength: 'Must be 15 characters or less '
                                    }}
                                />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col>
                                    <FormLabel htmlFor="tel">Tel.</FormLabel>
                                    <Control.text model=".tel" id="tel" className="form-control" 
                                    validators={{
                                        required, minLength: minLength(3), maxLength: maxLength(10), isNumber
                                    }} 
                                    value={this.state.tel} 
                                    onChange={(e) => this.setState({tel: e.target.value})}/>
                                    <Errors
                                    className="text-danger"
                                    model=".tel"
                                    show="touched"
                                    messages={{
                                        required: 'Required ',
                                        minLength: 'Must be greater than 2 numbers ',
                                        maxLength: 'Must be 10 numbers or less ',
                                        isNumber: 'Must be a number '
                                    }}
                                />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col>
                                    <FormLabel htmlFor="email">Email</FormLabel>
                                    <Control.text model=".email" id="email" className="form-control"
                                    validators={{
                                        required, validEmail
                                    }}
                                    value={this.state.email} 
                                    onChange={(e) => this.setState({email: e.target.value})}/>
                                    <Errors
                                    className="text-danger"
                                    model=".email"
                                    show="touched"
                                    messages={{
                                        required: 'Required ',
                                        validEmail: 'Invalid Email Address '
                                    }}
                                />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col>
                                    <FormLabel htmlFor="message">How can we help?</FormLabel>
                                    <Control.textarea rows={4} model=".message" id="message" className="form-control" 
                                    value={this.state.message} 
                                    onChange={(e) => this.setState({message: e.target.value})}/>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={2}>
                                    <Button type="submit" className="bg-primary mb-5">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </LocalForm>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Contact;
