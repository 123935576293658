import * as ActionTypes from './ActionTypes';

export const Applicants = (applicants = [], action) => {
    switch(action.type) {
        case ActionTypes.FETCH_ALL_APPLICANTS:
            return action.payload;
        case ActionTypes.CREATE_APPLICANT:
            return [...applicants, action.payload];
        case ActionTypes.DELETE_APPLICANT:
            return applicants.filter((applicant) => applicant._id !== action.payload);
        default: 
            return applicants;
    }
};
