import React, { Component } from 'react';
import { LocalForm, Control, Errors } from 'react-redux-form';
import { Row, Col, FormLabel, Modal, ModalHeader, ModalBody, Button } from 'react-bootstrap';
import FileBase from 'react-file-base64';

const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const isNumber = (val) => !isNaN(Number(val));
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class ModalForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstname: "", 
            lastname: "", 
            email: "", 
            telnum: "", 
            resume: ""
        }
    }

    handleSubmit = async () => {
        try {
            let res = await fetch('https://thecqtech.com/api/applicants', {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    jobPosition: this.props.job.jobPosition,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    email: this.state.email,
                    telnum: this.state.telnum,
                    resume: this.state.resume

                }),
            })
            let resJson = await res.json();

            if(res.status === 200) {
                this.clear();
                alert("Your application has been sent");
            }
            else {
                alert("Unable to send your application");
            }
        } catch (error) {
            console.log(error);
        }
    }

    clear() {
        this.setState({firstname: '', lastname: '', email:'', telnum: '', resume: ''})
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.handleClose}>
                <ModalHeader closeButton>Apply for {this.props.job.jobPosition}
                </ModalHeader>
                <ModalBody>
                    <LocalForm method="POST" onSubmit={this.handleSubmit.bind(this)}>
                        <Row className="form-group">
                            <Col>
                                <FormLabel htmlFor="firstname">First Name</FormLabel>
                                <Control.text model=".firstname" id="firstname" 
                                value={this.state.firstname} className="form-control"
                                validators={{
                                    required, minLength: minLength(3), maxLength: maxLength(15)
                                }} 
                                onChange={(e) => this.setState({firstname: e.target.value})}/>
                                <Errors
                                    className="text-danger"
                                    model=".firstname"
                                    show="touched"
                                    messages={{
                                        required: 'Required ',
                                        minLength: 'Must be greater than 2 characters ',
                                        maxLength: 'Must be 15 characters or less '
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col>
                                <FormLabel htmlFor="lastname">Last Name</FormLabel>
                                <Control.text model=".lastname" id="lastname" className="form-control"
                                validators={{
                                    required, minLength: minLength(3), maxLength: maxLength(15)
                                }}
                                value={this.state.lastname} 
                                onChange={(e) => this.setState({lastname: e.target.value})}/>
                                <Errors
                                    className="text-danger"
                                    model=".lastname"
                                    show="touched"
                                    messages={{
                                        required: 'Required ',
                                        minLength: 'Must be greater than 2 characters ',
                                        maxLength: 'Must be 15 characters or less '
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <Control.text model=".email" id="email" className="form-control" 
                                validators={{
                                    required, validEmail
                                }}
                                value={this.state.email} 
                                onChange={(e) => this.setState({email: e.target.value})}/>
                                <Errors
                                    className="text-danger"
                                    model=".email"
                                    show="touched"
                                    messages={{
                                        required: 'Required ',
                                        validEmail: 'Invalid Email Address '
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col>
                                <FormLabel htmlFor="telnum">Tel. </FormLabel>
                                <Control.text model=".telnum" id="telnum" className="form-control"
                                validators={{
                                    required, minLength: minLength(3), maxLength: maxLength(10), isNumber
                                }} 
                                value={this.state.telnum}
                                onChange={(e) => this.setState({telnum: e.target.value })}/>
                                <Errors
                                    className="text-danger"
                                    model=".telnum"
                                    show="touched"
                                    messages={{
                                        required: 'Required ',
                                        minLength: 'Must be greater than 2 numbers ',
                                        maxLength: 'Must be 10 numbers or less ',
                                        isNumber: 'Must be a number '
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col>
                                <FileBase type="file" multiple={false}
                                onDone={({ base64 }) => this.setState({resume: base64 })}/>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={3} md={2}>
                                <Button type="submit" className="bg-primary" onClick={this.props.handleClose}>
                                    Submit
                                </Button>
                            </Col>
                            <Col xs={3} md={3}>
                                <Button type="submit" onClick={this.props.handleClose}> 
                                    Cancel   
                                </Button>
                            </Col>
                        </Row>
                    </LocalForm>
                </ModalBody>
            </Modal>
        );
    };
} 

export default ModalForm;
