import * as ActionTypes from './ActionTypes';

export const Jobs = (jobs = [] ,action) => {
    switch(action.type) {
        case ActionTypes.FETCH_ALL_JOBS:
            return action.payload;

        case ActionTypes.CREATE_JOB:
            return [...jobs, action.payload];

        case ActionTypes.UPDATE_JOB:
            return jobs.map((job) => (job._id === action.payload._id ? action.payload: job));
        case ActionTypes.DELETE_JOB:
            return jobs.filter((job) => (job._id !== action.payload));
        default: 
            return jobs;
    }
}