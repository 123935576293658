import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Jobs } from './jobs';
import { Applicants } from './applicants';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
// import { Auth } from './auth';
// import { InitialFeedback } from './forms';

export const ConfigureStore = () => {
    const store = createStore(
        combineReducers({
            jobs: Jobs,
            applicants: Applicants
            // auth: Auth
        }),
        applyMiddleware(thunk, logger)
    );

    return store;
}