import React, { Component } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';

class PagesHeader extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isNavOpen: false,
            scrolled: false
        };

        this.toggleNav = this.toggleNav.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        // Check if user has scrolled down
        const scrolled = window.scrollY > 0;
        this.setState({ scrolled });
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    render() {
        const { scrolled } = this.state;
        return (
            <>
                <Navbar collapseOnSelect className={scrolled ? "pages-navbar scrolled" : "pages-navbar"} expand="md">
                    <Container fluid className="ml-3 mr-3">
                        <Navbar.Brand href="/home">
                            <img src="../assets/images/cqlogo.png" alt="CQ Technologies" className="brand-logo"/>    
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{color: "white"}}>
                            {/* <span className="fa fa-solid fa-bars" style={{color:scrolled? "#092a49": "white"}}></span> */}
                            <NavDropdown style={{ left : 'auto', right: 0}} title={<span className="fa fa-solid fa-bars" style={{color:scrolled? "#092a49": "white"}}></span>} flip={false}  id="nav-dropdown">
        <NavDropdown.Item eventKey="1" href='/home'>Home</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="2" href='/offerings'>Offerings</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="3" href='/careers'>Careers</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="4" href='/contact'>Contact</NavDropdown.Item>
      </NavDropdown>
                        </Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav" style={{display: 'flex', right: '50px', position : 'absolute'}} className='d-none d-sm-none d-md-none'>
                        
                        <Nav className="me-auto pull-right">
                            <Nav.Link href="/home" style={{color : scrolled? '' : 'white'}}>Home</Nav.Link>
                            <Nav.Link href="/offerings" style={{color : scrolled? '' : 'white'}}>Offerings</Nav.Link>
                            <Nav.Link href="/careers" style={{color : scrolled? '' : 'white'}}>Careers</Nav.Link>
                            <Nav.Link href="/contact" style={{color : scrolled? '' : 'white'}}>Contact</Nav.Link>
                        </Nav>
                        </Navbar.Collapse>
                        {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                        {/* <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-md`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                            placement="end"
                            responsive='md'
                        >
                            <Offcanvas.Header closeButton closeVariant='white' className="pages-navbar-md">
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                                    <Navbar.Brand href="/home" style={{width: "100%"}}>
                                        <img src="../assets/images/cqlogo.png" alt="CQ Technologies" className="brand-logo"/>    
                                    </Navbar.Brand>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1">
                                    <Nav.Link style={{color : scrolled? '' : 'white'}}  className={scrolled ? 'nav-link scrolled' : 'nav-link'} href="/home">Home</Nav.Link>
                                    <Nav.Link style={{color : scrolled? '' : 'white'}}  className={scrolled ? 'nav-link scrolled' : 'nav-link'} href="/offerings">Offerings</Nav.Link>
                                    <Nav.Link  style={{color : scrolled? '' : 'white'}}  className={scrolled ? 'nav-link scrolled' : 'nav-link'}href="/careers">Careers</Nav.Link>
                                    <Nav.Link style={{color : scrolled? '' : 'white'}}  className={scrolled ? 'nav-link scrolled' : 'nav-link'}href="/contact">Contact</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas> */}
                        {/* </Navbar.Collapse> */}
                    </Container>
                </Navbar>
            </>
        );
    }
}

export default PagesHeader;