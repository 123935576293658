import React, { useState } from 'react';
import { Container, Row, Col, Tabs, Tab, Button} from 'react-bootstrap';
import HomeHeader from './HomeHeaderComponent';

function Consulting() {
    return (
        <>
        {/* Consulting */}
        <Container className="ml-0 mr-0" style={{maxWidth: "fit-content", 
        paddingLeft: '0', paddingRight: '0'}}>
            <Row className="product-container">
            <Col sm={12} md={6} className="p-0 d-sm-none d-md-block">
                    <img src="../assets/images/consulting.jpeg" alt="Products" width="100%" height="100%"/>
                </Col>
            <Col sm={12} md={6} className='text-center' style={{ marginTop: "10%" }}>
                    <h1 style={{ color: "white", marginBottom: "3%" }}>Consulting</h1>
                    <p style={{ color: "white", paddingLeft: "7%", paddingRight: "7%" }}>After years of working with governments around the world, we converged on a consulting process which encourages innovative solutions and remains cognizant of subject matter expertise.</p><br/>
                </Col>
                <Col sm={12} md={6} className="p-0 d-none d-sm-block d-md-none">
                    <img src="../assets/images/consulting.jpeg" alt="Products" width="100%" height="100%"/>
                </Col>
            </Row>
        </Container>
            {/* <Row className="services-cards">
            <Col xs={12} md={4} lg={3} >
                    Examination Process Advisory
                    <Card className="text-center solutions-card" sx={{ maxWidth: 345 }}>
                        <img src='../assets/images/examad.png' className="mb-3"
                        alt="Examination Process Advisory" height="90" width="90"/>
                        <CardContent className="text-center">
                            <Typography gutterBottom variant='h5' component='div'> Examination Process Advisory </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    Software and IoT Technology
                    <Card className="text-center solutions-card">
                        <img src='../assets/images/Proctoring Icon 1-1-01.svg'
                        alt="Certificate" height="110" width="110"/>
                        <CardContent className="text-center">
                        <Typography gutterBottom variant='h5' component='div'> Software and IoT Technology</Typography>
                        <Typography variant='body2' color='text.secondary'>
                            <p>Capabale of generating the physical as well the digital certificate. </p>
                        </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    Digital Archiving and Content Management
                    <Card className="text-center solutions-card" sx={{ maxWidth: 345 }}>
                        <img src='../assets/images/da-and-cm.png' className="mb-3"
                        alt="Certificate" height="90" width="90"/>
                        <CardContent className="text-center">
                        <Typography gutterBottom variant='h5' component='div'> Digital Archiving and Content Management</Typography>
                        <Typography variant='body2' color='text.secondary'>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    <Card className="text-center solutions-card m-0" sx={{ maxWidth: 345 }}>
                        <a href="/examination">
                        <img src='../assets/images/Arrow 1-1 - Color.svg' className="ml-2"
                        alt="Right Arrow" height="120" width="120"/>
                        </a>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'> Learn More </Typography>
                        </CardContent>
                    </Card>
                </Col>
            </Row> */}
        <div className="container">
            <div className="row row-content justify-content-center mt-5">
                <div className="col-10 col-md-7">
                    <h1 style={{color: "#092a49"}}>Service Design Overview</h1>
                    <p>When one of the most prestigious Examinations Councils in Africa asked us to help them undergo a digital transformation of their Item Banking process, it had a simple brief: Ensure a great customer experience for exam administrators by digitizing manual processes.</p> 
                    <p>As we began to examine the pieces that made up customer experience — specifically, how exam admins created content (items) — we discovered it wasn’t so simple to build a great customer experience. Beyond creating exam content, there were many factors at play in the successes and failures of the Council’s technology migration, including how roles were structured, work processes, communication channels, feedback loops, and more.</p>
                    {/* <p>
                    Enter service design.
                    </p> */}
                </div>
            </div>
            <div className="row row-content justify-content-center">
                <div className="col-10 col-md-7">
                    <div>
                    <h2 style={{color: "#092a49"}}>What service design is</h2>
                    <p className="mb-5">Service design enables us to create sustainable and high-quality experiences for both customers and the beneficiaries of the services they provide. Using a set of principles, activities, and methods, the discipline takes a holistic look across a service ecosystem. This includes the “frontstage” (what a customer sees) and the “backstage” (behind-the-scenes organizational structures).</p>
                    <img src="../assets/images/Behind the Curtains Illustration 1-1.png" alt="Consulting" width="100%" height="60%" style={{objectFit: "contain"}}/>
                    <p className="mt-5">
                    While many organizations focus on improvements to customer experience — which was the case with council’s emphasis on digitizing manual processes — frontstage-only solutions often fail without consideration for the systems that support them.
                    </p>
                    <p>
                    For the council and government organizations of all types, service design provides the tools to understand and improve the whole system. In the Council’s case, this meant looking at the technologies, staff within the office, users that interact with exam data outside the organization, and how they all work together. It meant bringing people along in the process to identify problems, come up with the right solutions, and put them into action.
                    </p>
                    </div>
                </div>
            </div>
            <div className="row row-content justify-content-center">
                <div className="col-10 col-md-7">
                    <h2 style={{color: "#092a49"}}>When to use service design</h2>
                    <p>As was the case for the Council, many projects don’t begin with a plan to use service design. Once you dig into a problem, though, the complexities start to emerge and the need for service design becomes clear.</p>
                    <p>Recognizing from the outset what kinds of problems require service design isn’t always easy, but there are patterns to look out for. According to a Deloitte study of government service design projects, each of these problems can present a good opportunity for service design:</p>
                    <ul>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Lack of knowledge about the customers you serve</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Siloed communication, processes, and systems within an organization</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Customers with unique and specific needs</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Outdated, fragmented, and insufficient technology systems</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Poor customer service</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Complicated legal and political landscape</p></li>
                    </ul>
                    <p>
                    Governments bodies deliver many different layered services, from legislation and policy to digital products. No matter the exact problem we’re trying to solve, this kind of complex landscape is a great fit for a service design approach.
                    </p>
                </div>
            </div>
            <div className="row row-content justify-content-center pb-5">
                <div className="col-10 col-md-7">
                    <h2 style={{color: "#092a49"}}>Who uses service design</h2>
                    <p>
                    In service design projects, we usually involve a small team of service designers in partnership with a core government team that owns or helps manage a service. Projects also include key stakeholders, such as organization leadership, staff who administer or interact with a service, and customers of a service. Depending on the nature of a project, teams may include an interdisciplinary mix of roles such as engineers, visual designers, and customer support staff.
                    </p>
                    <p>
                    While the makeup of our service design teams can vary, there are some essential capacities needed for a successful project, including:
                    </p>
                    <ul>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Decision-making skills</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Scoping and understanding what’s viable</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Championing and teaching the process</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Allowing voices and ideas to be heard</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Knowledge of methods and when to use them</p></li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    );
}

function ExaminationServices () {
    return (
        <>
        {/* Examination Services */}
        <Container className="ml-0 mr-0" style={{maxWidth: "inherit", 
        paddingLeft: '0', paddingRight: '0'}}>
            <Row className="service-container">
                <Col sm={12} md={6} className='text-center' style={{ marginTop: "10%" }}>
                    <h1 style={{ color: "white", marginBottom: "3%" }}>Examination Services</h1>
                    <p style={{ color: "white", paddingLeft: "7%", paddingRight: "7%"  }}>Clients can leverage any part of our Examination Services spectrum as a whole, and yet only pay for utilized services and not for the entire cost of the solution.
                    Plus, with around the clock support and customer first thinking, we build relationships with you that enable us to grow together.</p><br/>
                </Col>
                <Col sm={12} md={6} className="p-0">
                    <img src="../assets/images/Item Banking.jpg" alt="Products" width="100%" height="100%"/>
                </Col>
            </Row>
            {/* <Row className="services-cards ml-5 mr-5"> */}
            {/* <Col xs={12} md={4} lg={3}>
                    Item Banking
                    <Card className="text-center solutions-card" sx={{ maxWidth: 345 }}>
                            <img height="120" width="120" src='../assets/images/Item Banking Icon 1-1-01.svg' 
                            alt="Item Banking" className="ml-2"/>   
                            <CardContent>
                                <Typography gutterBottom variant='h5' component='div'> Item <br/> Banking </Typography>
                                <Typography variant='body2' color='text.secondary' className="mr-1">
                                    <p>Offline and online process ensuring the exams conducted are error free.</p>
                                </Typography>
                            </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    Question Paper Printing
                    <Card className="text-center solutions-card">
                        <img src="../assets/images/Paper and Pencil Exam Icon 1-1-01.svg" alt="Question Paper Printing"
                        height="120" width="120" className="ml-2"/>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component="div">
                                Question Paper Printing
                            </Typography>
                            <Typography variant='body2' color='text.secondary'>
                            <p>Complete online mananagement system enables the power of digitization of exams.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    OMR Solutions
                    <Card className="text-center solutions-card" sx={{ maxWidth: 345 }}>
                        <img src='../assets/images/OMR Scanning Icon 1-1-01.svg'  className="ml-2"
                        alt="OMR Solutions"  height="120" width="120"/>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'> OMR<br/> Solutions </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                <p>Process OMR sheet within a second with AI powered OMR processing software.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    Certificate
                        <Card className="text-center solutions-card" sx={{ maxWidth: 345 }}>
                            <img src='../assets/images/Security Printing Icon 1-1-01.svg'  className="ml-2"
                            alt="Certificate" height="120" width="120"/>
                            <CardContent className="text-center">
                                <Typography gutterBottom variant='h5' component='div'> Certificate<br/> Printing</Typography>
                                <Typography variant='body2' color='text.secondary'>
                                    <p>Capabale of generating the physical as well the digital certificate. </p>
                                </Typography>
                            </CardContent>
                        </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    eAssessment
                    <Card className="text-center solutions-card" sx={{ maxWidth: 345 }}>
                        <img src='../assets/images/Proctoring Icon 1-1-01.svg' className="ml-2"
                        alt="eAssessment" height="120" width="120"/>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'> Online <br/> Examination </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>        
                <Col xs={12} md={4} lg={3}>
                    <Card className="text-center solutions-card mt-0" sx={{ maxWidth: 345 }}>
                        <a href="/examination">
                        <img src='../assets/images/Arrow 1-1 - Color.svg' className="ml-2"
                        alt="Right Arrow" height="120" width="120"/>
                        </a>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'> Learn More </Typography>
                        </CardContent>
                    </Card>
                </Col> */}
            {/* </Row> */}
        </Container>
        <div className="container ml-0 mr-0 pl-0 pr-0 mt-5" style={{maxWidth: "fit-content"}}>
            <div className="row row-content justify-content-center mb-5" id="item_banking" style={{maxWidth: "fit-content", marginLeft: "0px", marginRight: "0px"}}>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 mr-3">
                    <span className="offerings-para"><img src='../assets/images/Item Banking Icon 1-1-01.svg' className="mr-3" alt="Examination Process Advisory" height="100px"/>Item Banking</span>
                    <p>With the introduction of modern, agile software, our system is designed to be integrated, efficient, and resource-optimized. This leads to a significant reduction in administrative efforts and minimizes the costs associated with repetitive tasks, which are not only time-consuming but also prone to errors.</p>
                    <p>Our system ensures the integrity of content preservation and test construction. Some of the key features of our state-of-the-art solution are Dynamic Workflows, AI-assisted translation, and Audit Trails. They reflect our commitment to advancing best practices and adherence to industry standards.</p>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 align-self-center">
                    <video className="ibank-video" controls autoPlay preload="auto" muted loop>   
                        <source src="../assets/images/item-banking.mp4"
                        type="video/mp4"/>
                        Sorry, your browser doesn't support videos.
                    </video>
                </div>
            </div>
            <div className="row row-content justify-content-center pt-5 pb-5" style={{backgroundColor: "#F8F8F8", maxWidth: "fit-content", marginLeft: "0px", marginRight: "0px"}}>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 ">
                    <span className="offerings-para"><img src='../assets/images/Paper and Pencil Exam Icon 1-1-01.svg' className="mr-3 float-left mt-1" alt="Question Papers" height="80px"/>
                    <span className='align-self-center' style={{verticalAlign: 'middle'}}> Question Papers</span>
                    </span>
                    <p>
                    Academic, psychometric, and personnel testing have been dominated by the paper-and-pencil test for a long time. For high volume, simultaneous examination, paper and pencil continues to be the preferred medium even with the rise of computer based testing.
                    </p>
                    <ul>
                        <li style={{color: "black", fontSize: "1.125rem"}}>
                        Unparalleled Security: Our industry leading approach utilises many layers of security from the paper to the outermost packaging, to the supply chain security.
                            {/* <ul>
                                <li style={{color: "black", fontSize: "1.125rem"}}>GPS locks</li>
                                <li style={{color: "black", fontSize: "1.125rem"}}>One-time locking seals</li>
                            </ul> */}
                        </li>
                        <br/>
                        <li style={{color: "black", fontSize: "1.125rem"}}>
                        Unbounded Capacity: We develop lean solutions for Question Paper projects, giving them the legs to scale to millions of papers printed per day.                        </li>
                        <br/>
                        {/* <li style={{color: "black", fontSize: "1.125rem"}}>
                        Enduring Reliability: Around the clock support and customer first thinking. For us, that means building relationships that allow us to grow together.
                        </li> */}
                    </ul>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 mr-3 align-self-center">
                    <img src="../assets/images/Paper and Pencil Exam Illustration.svg" alt="Question Papers" width="100%" height="100%" style={{objectFit: "contain"}}/> 
                </div>
            </div>
            <div className="row row-content justify-content-center pt-5 pb-5" style={{maxWidth: "fit-content", marginRight: "0px", marginLeft: "0px"}}>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 mr-3">
                    <span className="offerings-para">
                        <img src='../assets/images/OMR Scanning Icon 1-1-01.svg' className="mr-3 mt-1 float-left" alt="OMR Solutions" height="80px"/>
                        <span className='align-self-center' style={{verticalAlign: 'middle'}}> OMR Solutions</span></span>

                    <p>Our Answer Sheet Solution covers the entire process from scanning to processing the results. It offers a fast, hardware independent, scalable solution for scanning and processing a high volume of answer sheets. There are two techniques that we utilize: OMR Scanning and Image Scanning.</p>
                    <p>OMR Scanning utilizes purpose-built scanners which have reader heads that sense the presence of filled bubbles while Image Scanning uses multipurpose, low-cost scanners to obtain images of the answer sheets which are processed by our software to produce the results.</p>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 align-self-center">
                    <img src="../assets/images/OMR Scanning Illustration.svg" alt="OMR Solutions" width="100%" height="90%" style={{objectFit: "contain"}}/>
                </div>
            </div>
            {/* <div className="row row-content justify-content-center" style={{backgroundColor: "#F8F8F8", maxWidth: "fit-content", marginLeft: "0px", marginRight: "0px"}}>
                <div className="col-10 col-md-4 mt-5 mb-5">
                    <span className="offerings-para"><img src='../assets/images/Security Printing Icon 1-1-01.svg' className="mr-3 mt-1 float-left" alt="Certificate Printing" height="15%" width="15%"/>Security Printing</span>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
                <div className="col-12 col-md-4 mr-3">
                    <img src="../assets/images/Security Printing 1.jpg" alt="Certificate Printing" width="100%" height="75%" style={{objectFit: "contain"}}/>
                </div>
            </div> */}
            <div className="row row-content justify-content-center pt-5 pb-5" style={{maxWidth: "fit-content", marginRight: "0px", marginLeft: "0px",backgroundColor: "#F8F8F8", marginTop : '20px', paddingTop: '25px'}}>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4">
                <span className="offerings-para">
                    <img src='../assets/images/Proctoring Icon 1-1-01.svg' className="mr-3 mt-1 float-left" alt="eAssessment" height="100px"/>
                <span className='align-self-center' style={{verticalAlign: 'middle'}}> Cloud Examination</span>
                </span>
                    <p>
                    To handle dynamic and rapidly changing examination environments, the online medium provides unmatched flexibility. Ad-hoc scheduling allows administrators to plan, conduct, and get results within hours, a process which traditionally takes weeks.
                    </p>
                    <ul>
                        <li style={{color: "black", fontSize: "1.125rem"}}>
                            Planning: Exam events can be created, and students can be grouped, in one seamless flow.
                        </li>
                        <li style={{color: "black", fontSize: "1.125rem"}}>
                            Conducting: Exams are automatically deployed to the server and the dashboard provides insight about each candidate’s progress and status.
                        </li>
                        <li style={{color: "black", fontSize: "1.125rem"}}>
                            Results: The results for all students are reported automatically at the end of a session.
                        </li>
                    </ul>
                    <p>
                    In addition, we supply a Proctor module which allows administrators to monitor each candidate that takes the exam. Audio, video, or periodic photos can be captured from the same device that the assessment is taking place from.
                    </p>
                </div>
                <div className="col-12 col-sm-10 col-md-10 col-lg-4 align-self-center">
                    <img src="../assets/images/Proctored Online Exam Illustration.svg" alt="eAssessment" width="100%" height="67%" style={{objectFit: "contain"}}/>
                </div>
            </div>
        </div>
        </>
    );
}

function ITServices () {
    return (
        <>
        {/* IT Services */}
        {/* Our IT Services are about offering internal clients the power of choice. They pay only for what they use and get it quickly. */}
        <Container className="ml-0 mr-0" style={{maxWidth: "fit-content", 
        paddingLeft: '0', paddingRight: '0'}}>
            <Row className="product-container">
            <Col sm={12} md={6} className='text-center' style={{ marginTop: "10%" }}>
                    <h1 style={{ color: "white", marginBottom: "3%" }}>IT Services</h1>
                    <p style={{ color: "white", paddingLeft: "7%", paddingRight: "7%" }}>Our IT Services offer clients the power of choice with a focus on innovation, agility, and aligning resources to minimize infrastructure investments and capital expenditure.</p><br/>
                </Col>
            <Col sm={12} md={6} className="p-0">
                    <img src="../assets/images/Online Exam 2.jpg" alt="Products" width="100%" height="100%"/>
                </Col>
            </Row>
        </Container>
        
        <div className="container ml-0 mr-0 pl-0 pr-0" style={{maxWidth: "fit-content"}}>
        <div className="row row-content justify-content-center pt-5 pb-5" style={{backgroundColor: "#F8F8F8", maxWidth: "fit-content", marginLeft: "0px", marginRight: "0px"}}>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4">
                    <span className="offerings-para"><img src="../assets/images/Biometric Verification Icon 1-1-01.svg" height="80px" alt="Biometrics" className="mr-3 mt-1 float-left"/>
                    <span className='align-self-center' style={{verticalAlign: 'middle'}}> Biometrics</span>
                    </span>
                    <p>
                    We use proprietary Biometric Verification software to verify process stakeholders and maintain unique identification by sensing a person’s face, fingerprint, and retina.                     </p>
                    <p>
                    Cloud technology is leveraged to make Biometric Verification more accessible and resilient. Cloud-based systems enable agencies and organizations to perform biometric identification in a secure way.  
                    </p>
                    {/* <p>
                    Cloud technology is often used to make biometric information more accessible and portable. Cloud-based systems enable agencies and organizations to perform biometric identification on any individual, regardless of their location.
                    </p> */}
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 mr-3 align-self-center">
                    <img width="100%" height="70%" src="../assets/images/Biometric Verification Illustration.svg" alt="Biometrics" style={{objectFit: "contain", marginTop: "3%"}}/>
                </div>
            </div>
            <div className="row row-content justify-content-center pt-5 pb-5">
                <div className="col-10 col-sm-10 col-md-10 col-lg-4"> 
                <span className="offerings-para"><img src="../assets/images/Customized Software Icon 1-1-01.svg" height="100px" alt="Customized Software" className="mr-3 float-left"/>
                <span className='align-self-center' style={{verticalAlign: 'middle'}}> Customized Software</span>
                </span>
                    <p className="justify-content-left">
                    Working in concert with our service design consulting approach, our talented engineering team tackles the toughest software challenges. From real-time high performance computing to integrating human centered Artificial Intelligence, to building systems resilient to minimal if not absent internet connectivity.
                    </p>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 mr-3 align-self-center">
                    <img src="../assets/images/joinus.jpg" alt="Customized Software"
                    height="100%" width="100%" style={{objectFit: "contain"}}/>
                </div>
            </div>
            {/* <div className="row row-content justify-content-center" style={{backgroundColor: "#F8F8F8", maxWidth: "fit-content", marginLeft: "0px", marginRight: "0px"}}>
                <div className="col-10 col-md-4 mt-5 mb-5"> 
                    <span className="offerings-para"><img src="../assets/images/Online Exam Icon 1-1-01.svg" height="15%" width="15%" alt="Customized Software" className="mr-3 mt-3 float-left"/>
                    Infrastructure Management</span>
                    <p className="justify-content-left">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
                <div className="col-10 col-md-4">
                    <img src="../assets/images/carousel-21.JPG" alt="Infrastructure Management" height="95%" width="100%" style={{objectFit: "contain"}}/>
                </div>
            </div> */}
        </div>
        </>
    );
}


function Offerings() {
    // const [selectedTab, setSelectedTab] = useState('consulting');

    return (
        <>
        <HomeHeader />
        <Container fluid className="p-0">
            <Row className="p-0 m-0">
                <div className="pages-div">
                    <img src="../assets/images/CQ Tech Website BG Design Option 2.png" alt="Our Company" width="100%"/>
                </div>
                <p className="p-0 m-0">
                    <p className="page-heading">How We Help Others</p>
                </p>
            </Row>
        </Container>

        <div className="container p-0 mt-1" style={{maxWidth: "100%"}}>
            <div >
                <Tabs
                defaultActiveKey="examination"
                // activeKey={selectedTab}
                id="justify-tab-example"
                className="mb-0"
                // onSelect={(e) => setSelectedTab(e)}
                fill
                justify>
                    <Tab eventKey="examination" id="examination" title="Examination as a Service">
                        <ExaminationServices />
                    </Tab>
                    <Tab eventKey="consulting" title="Consulting" color="black">
                        <Consulting />
                    </Tab>
                    <Tab eventKey="itservices" title="IT as a Service">
                        <ITServices />
                    </Tab>
                </Tabs>
            </div>
        </div>
        <Container fluid className="d-flex align-items-center pt-5" style={{backgroundColor: "#C0C0C0"}}>
                <Row>
                    <div className="d-flex justify-content-center mt-3">
                        <p className="pages-end mb-0">Let's work together :</p>
                    </div>
                    <div className="d-flex justify-content-center text-center">
                        <p className="pages-end-para">Reach out to us to chat about how we can collaborate and deliver value.</p>
                    </div>
                    <div className="d-flex justify-content-center text-center mb-5 mt-3">
                        <Button variant="outline" type="submit" href="/contact"
                        className="contact-btn rounded-3">
                            Contact Us
                        </Button>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default Offerings;