import React from 'react';
import PagesHeader from './PagesHeaderComponent';
import { Container, Row, Button } from 'react-bootstrap';

function CaseStudy2() {
    return (
        <>
        <PagesHeader/>
        <div className="container">
            <div className="row row-content mt-3 justify-content-center">
                <div className="col-12 col-md-7">
                    <article style={{marginTop: "10%", marginBottom: "10%"}}>
                        <h5 style={{color: "gray"}}>DEPARTMENT OF IT</h5>
                        <h1 className="mb-3">Zero Day Project During Christmas for an International Client</h1>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Egestas quis ipsum suspendisse ultrices gravida dictum fusce ut. Dictumst vestibulum rhoncus est pellentesque elit. Urna neque viverra justo nec. Interdum velit euismod in pellentesque massa placerat duis. Diam vulputate ut pharetra sit amet aliquam id diam maecenas. Facilisis magna etiam tempor orci eu. Ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Ornare massa eget egestas purus viverra accumsan in nisl nisi. Ac placerat vestibulum lectus mauris ultrices eros in. Vehicula ipsum a arcu cursus. Massa tincidunt dui ut ornare lectus sit amet. Congue mauris rhoncus aenean vel elit. Adipiscing elit duis tristique sollicitudin nibh. Porta nibh venenatis cras sed. Ut aliquam purus sit amet luctus venenatis lectus magna fringilla. Amet nisl suscipit adipiscing bibendum est ultricies integer quis. Mattis rhoncus urna neque viverra. Molestie at elementum eu facilisis sed odio morbi quis commodo.
                        </p>
                        <img src="../assets/images/joinus.jpg" alt="Case Study 1"
                        width="100%" height="100%" className="mb-5"/>
                        <p>
                        Viverra justo nec ultrices dui sapien. Porta nibh venenatis cras sed. Amet luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Nibh tortor id aliquet lectus proin. Nec sagittis aliquam malesuada bibendum arcu vitae. Arcu cursus vitae congue mauris rhoncus aenean. Lorem ipsum dolor sit amet consectetur adipiscing. Eget nunc scelerisque viverra mauris in aliquam sem fringilla ut. Ac orci phasellus egestas tellus rutrum. Etiam sit amet nisl purus in. Accumsan tortor posuere ac ut consequat semper viverra nam libero. At in tellus integer feugiat scelerisque varius morbi enim nunc. Id cursus metus aliquam eleifend mi. In fermentum posuere urna nec tincidunt praesent semper. Neque volutpat ac tincidunt vitae semper. Mauris in aliquam sem fringilla ut morbi tincidunt augue. Purus viverra accumsan in nisl nisi scelerisque eu ultrices vitae. Lacinia at quis risus sed vulputate odio ut enim.
                        </p>
                    </article>
                </div>
            </div>
        </div>
        <Container fluid className="d-flex align-items-center" style={{backgroundColor: "#C0C0C0", height: "45vh"}}>
                <Row>
                    <div className="d-flex justify-content-center mt-3">
                        <p className="pages-end mb-0">Let's work together</p>
                    </div>
                    <div className="d-flex justify-content-center text-center">
                        <p className="pages-end-para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div className="d-flex justify-content-center text-center mb-5 mt-3">
                        <Button variant="outline" type="submit" href="/contact"
                        className="contact-btn rounded-3">
                            Contact Us
                        </Button>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default CaseStudy2;