import React, { Component } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }
  
  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
  };
  

class HomeHeader extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isNavOpen: false,
            isModalOpen: false,
            scrolled: false        };

        this.toggleNav = this.toggleNav.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
      }
    
      componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
      }
    
      handleScroll() {
        // Check if user has scrolled down
        const scrolled = window.scrollY > 0;
        this.setState({ scrolled });
      }
    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }
    render() {
        const { scrolled } = this.state;        return (
            <>
             <HideOnScroll >
                <Navbar collapseOnSelect expand="md"  className={scrolled ? 'navbar scrolled' : 'navbar'} >
                    <Container fluid className="ml-3 mr-3">
                        <Navbar.Brand href="/home">
                            <img src="../assets/images/cqlogo.png" alt="CQ Technologies" className="brand-logo"/>
                        </Navbar.Brand>
                        
                        <Navbar.Toggle expanded={false} aria-controls="basic-navbar-nav" style={{color: "white"}}>
                            {/* <span className="fa fa-solid fa-bars" style={{color:scrolled? "#092a49": "white"}}></span> */}
                            <NavDropdown style={{ left : 'auto', right: 0}} title={<span className="fa fa-solid fa-bars" style={{color:scrolled? "#092a49": "white"}}></span>} flip={false}  id="nav-dropdown">
        <NavDropdown.Item eventKey="1" href='/home'>Home</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="2" href='/offerings'>Offerings</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="3" href='/careers'>Careers</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="4" href='/contact'>Contact</NavDropdown.Item>
      </NavDropdown>
                        </Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav" style={{display: 'flex', right: '50px', position : 'absolute'}} className='d-none d-sm-none d-md-none'>
                        
                        <Nav className="me-auto pull-right">
                            <Nav.Link href="/home" style={{color : scrolled? '' : 'white'}}>Home</Nav.Link>
                            <Nav.Link href="/offerings" style={{color : scrolled? '' : 'white'}}>Offerings</Nav.Link>
                            <Nav.Link href="/careers" style={{color : scrolled? '' : 'white'}}>Careers</Nav.Link>
                            <Nav.Link href="/contact" style={{color : scrolled? '' : 'white'}}>Contact</Nav.Link>
                        </Nav>
                        </Navbar.Collapse>
                        {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                    </Container>
                </Navbar>
                </HideOnScroll>
            </>

        );
    }
}

export default HomeHeader;