import React from 'react';
import PagesHeader from './PagesHeaderComponent';

export const Loading = () => {
    return(
        <>
        <PagesHeader />
        <div className="col-1 loading">
            <span className="fa fa-spinner fa-pulse fa-3x fa-fw text-primary"></span>
            <p>Loading . . .</p>
        </div>
        </>
    );
};