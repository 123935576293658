import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";

const targetedCountries = [
  "Singapore", "United States", "Thailand", "South Africa", "Kuwait", "Saudi Arabia", "India", "Nigeria", "Kenya", "Zambia", "Uganda", "Eswatini", "Tanzania",
  "United Arab Emirates", "Bahrain", "Qatar", "Sri Lanka", "Nepal", "Malaysia", "Oman",
];

const MapChart = () => {
    return (
        <ComposableMap data-tip="">
          <Geographies geography={`${process.env.PUBLIC_URL}/features.json`}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const isTargeted = targetedCountries.includes(geo.properties.name);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={isTargeted ? "#345372" : "#A7A7A8"} // Dark blue for targeted countries
                    stroke="gray"
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
    );
};

export default MapChart;
