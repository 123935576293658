import React, { Component } from 'react';
import { Container, Row, Button, Col } from 'react-bootstrap'; 
import { Card, CardContent, Typography } from '@material-ui/core';
import HomeHeader from './HomeHeaderComponent'; 
import Typed from 'react-typed';
// import Offerings from './OfferingsComponent';

function RenderCards() {
    return (
        <>
        <Container className="mt-3">
        <h2 style={{marginLeft:'17px'}} className='how-help'>How We Help Others</h2>
            <Row className="justify-content-md-center">
            <Col xs={12} md={6}>
                <Card className="d-flex align-items-flex-start solutions-card">
                        <img className='ml-3 mr-3 mt-3' height="18%" width="18%" 
                        src="../assets/images/Service Delivery.svg"
                        alt="Service Delivery"/>
                        <CardContent className="vr-line mt-0 mb-0 pt-0 pb-0"
                        style={{maxHeight: "fit-content"}}>
                            <Typography gutterBottom variant='h5' component="div">
                            Service Delivery
                            </Typography>
                            <Typography className="text-left" gutterBottom variant="body2" component="text.secondary">
                            <p>Administrators expect examination operations to be delightful, secure, and empowering. We specialize in delivering exceptional solution architectures out of your most complex challenges.</p>
                            </Typography>
                        </CardContent>
                        </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card className="d-flex align-items-flex-start solutions-card">
                        <img className='mr-3 ml-3' src="../assets/images/Procuement Icon.svg" height="18%" width="18%" 
                        alt="Procurement"/>
                        <CardContent className="vr-line mt-0 mb-0 pt-0 pb-0"
                        style={{maxHeight: "fit-content"}}>
                            <Typography gutterBottom variant='h5' component="div">
                            Procurement
                            </Typography>
                            <Typography className="text-left" gutterBottom variant="body2" component="text.secondary">
                            <p>To support digital progress, procurement must change. We combine technical and business brains to make procurement work for you in the digital age. That means, faster delivery of value, higher quality results, lower costs, and more breakthrough innovations.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card className="d-flex align-items-flex-start solutions-card">
                        <img className='ml-3 mr-3 mt-3' src="../assets/images/Transformation.svg" alt="Certificate"
                        height="18%" width="18%"/>  
                        <CardContent className="vr-line mt-0 mb-0 pt-0 pb-0"
                        style={{maxHeight: "fit-content"}}>
                            <Typography gutterBottom variant='h5' component="div">
                            Transformation
                            </Typography>
                            <Typography className="text-left" gutterBottom variant="body2" component="text.secondary">
                            <p>“Going digital” is about people first and technology second. We possess first-hand experience in adding the “e” in e-government by putting the processes and tools in place to unlock inherent potential. </p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card className="d-flex align-items-flex-start solutions-card">
                        <img className="ml-3 mr-3 mt-3" height="18%" width="18%"
                        src="../assets/images/Training Icon.svg"
                        alt="Training" style={{objectFit: "contain"}}/>
                        <CardContent className="vr-line mt-0 mb-0 pt-0 pb-0"
                        style={{maxHeight: "fit-content"}}>
                            <Typography gutterBottom variant='h5' component="div">
                            Training
                            </Typography>
                            <Typography gutterBottom variant="body2" component="text.secondary">
                            <p>We extend our know-how of a variety of processes within the exam lifecycle to help you develop and grow your digital organization.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
            </Row>
            </Container>
            <Container>
            <Row>
                <Col xs={12} md={12}>
                        <Button className="pull-right learn-more-btn rounded-3 mb-3" type="submit" href="/offerings" variant="outline-secondary">
                            Learn More</Button>
                </Col>
            </Row>
        </Container>
        </>
    );
}

function RenderJumbotron() {
    return (
        <>
            <Row className="p-0 m-0">
                <div className="home-div">
                <img src="../assets/images/CQ Tech Website BG Design Option 2.png" alt="Our Company" width="100%" className=''/> 
                {/* d-none d-md-block */}
                {/* <img src="../assets/images/pastedImage.png" alt="Our Company" width="100%" className='d-none d-xs-block d-sm-block d-md-none'/> */}
                </div>
                <p className="p-0 m-0">
                    <div className="home-heading">
                        <Typed 
                        strings={[
                            'Optimisation,',
                            'Examination,',
                            'Solutions,'
                        ]}
                        typeSpeed={60}
                        backSpeed={45}
                        startDelay={1000}
                        backDelay={2000}
                        loop/> 
                    </div>
                    <h1 className="home-unlocked">unlocked.</h1>
                    <p className="home-para">
                        Streamlined IT and consulting services for worldwide examinations
                    </p>
                </p>
            </Row>
        </>
    );
}

function RenderFacts() {
    const handleToggle = () => {
        
    }

    return (
        <>
        <Container style={{ marginTop : '40px' }}>
        <Row >
            <Col xs={{span: 12, order: 1}} md={{span: 4, order: 1}} className="d-flex align-items-center justify-content-center p-0">
               <img src="../assets/images/Item Banking Icon 1-1-01.svg" 
               alt="Item Banking" width="50%" height="50%"/>
            </Col>
            <Col xs={{span: 12, order: 2}} md={{span: 8, order: 2}}>
                <div className="circle">
                    <img src="../assets/images/circle-1.png" alt="Circle" 
                    width="100%" height="100%" style={{opacity: "0.6"}}/>
                </div>
                <div className="circle-text">
                    <p className="home-cs" style={{lineHeight: "1.5"}}>Item Banking is managing <span className="home-fact" style={{color: "#00afef"}}> 200+ </span> 
                    subjects in <span className="home-fact" style={{color: "#00afef"}}> 38+</span> languages for one client</p>
                    {/* <a onClick={() => <Offerings setSelectedTab={handleToggle}/> } href="/offerings" className="home-links">
                        Read more<span className="fa fa-arrow-right ml-1"></span></a> */}
                    <a class="more"  href="/offerings"  rel="noreferrer" style={{color: "#00afef",fontSize:"22px",fontWeight:700}}>
                        Learn More<i class="fa fa-arrow-right" style={{marginLeft:"10px"}} />
                    </a>
                </div>
            </Col>
        </Row>
        <Row style={{marginTop: "15%", marginBottom: "15%"}}>
            <Col xs={{span: 12, order: 1}} md={{span: 3, order: 2}} className="d-flex align-items-center justify-content-center p-0">
               <img src="../assets/images/OMR Scanning Icon 1-1-01.svg" 
               alt="OMR Scanning" width="50%" height="50%"/>
            </Col>
            <Col xs={{span: 12, order: 2}} md={{span: 9, order: 1}}>
                <div className="circle">
                    <img src="../assets/images/circle-2.png" alt="Circle" width="100%" height="100%" style={{opacity: "0.6"}}/>
                </div>
                <div className="circle-text">
                    <p className="home-cs" style={{lineHeight: "1.5"}}>
                    Enabled a client to design, print, and process <span className="home-fact">14 Million</span> OMR self-sustainably
                    </p>
                    <a class="more"  href="/offerings"  rel="noreferrer" style={{color: "#00afef",fontSize:"22px",fontWeight:700}}>
                        Learn More<i class="fa fa-arrow-right" style={{marginLeft:"10px"}} />
                    </a>
                </div>
            </Col>
        </Row>
        </Container>
        </>
    );
}

function RenderCaseStudies() {
    return (
        <>
        <Container>
            {/* <Row style={{marginBottom: "20%", 
                    marginLeft: "1%"}}>
                <Col xs={{span: 12, order: 1}} md={{span: 4, order: 1}} className="d-flex align-items-center justify-content-center">
                    <img src="../assets/images/Paper and Pencil Exam Icon 1-1-01.svg" alt="Case study figures" width="50%" height="50%"/>
                </Col>
                <Col xs={{span: 12, order: 2}} md={{span: 8, order: 2}}>
                    <div className="circle">
                        <img src="../assets/images/circle-1.png" alt="Circle" width="100%" height="100%" style={{opacity: "0.6"}}/>
                    </div>
                    <div className="circle-text">
                        <p className="home-cs" style={{lineHeight: "1.5"}}>
                        <span className="home-fact" style={{color: "#092a49"}}>15 Million </span> Question papers, printing and delivering worldwide annually 
                        </p>    
                    </div>
                </Col>
            </Row> */}
            <Row style={{marginBottom: "40px", marginLeft: "1%"}}>
                <Col  xs={{span: 12, order: 1}} md={{span: 4, order: 1}} className="d-flex align-items-center justify-content-center">
                    <img src="../assets/images/Proctoring Icon 1-1-01.svg" 
                    alt="Cloud Exam" width="50%" height="50%"/>
                </Col>
                <Col xs={{span: 12, order: 2}} md={{span: 8, order: 2}}>
                    <div className="circle">
                        <img src="../assets/images/circle-3.png" alt="Circle"
                        width="100%" height="100%" style={{opacity: "0.6"}}/> 
                    </div>
                    <div className="circle-text">
                        <p className="home-cs" style={{lineHeight: "1.5"}}>
                        Scalable cloud exams are <span className="home-fact" style={{color: "#00afef"}}>63%</span> more <span style={{whiteSpace: "nowrap"}}> cost effective </span> and <span className="home-fact" style={{color: "#00afef"}}>85%</span> more time efficient
                        </p>
                        <a class="more"  href="/offerings" target="_blank" rel="noreferrer" style={{color: "#00afef",fontSize:"22px",fontWeight:700}}>
                        Learn More<i class="fa fa-arrow-right" style={{marginLeft:"10px"}} />
                    </a>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
}

function RenderAbout() {
    return (
            <Row style={{backgroundColor: "#092a49"}}>
                <Col xs={12} sm={12} md={6}>
                    <p className="our-impact">Our impact.</p>
                    <p className="our-para">We enable clients to innovate and conduct quality exams across more than 40 nations. We are focused on improving customer experience at every stage of the process from content creation to certification and result publishing. </p>
                </Col>
                <Col xs={12} sm={12} md={6}>
                    {/* <img src="../assets/images/our-impact.png" alt="Our impact" 
                    className="ml-0 our-image"/> */}
                    <img src="../assets/images/Our Impact Illustration.svg" alt="Our impact" 
                    className="ml-0 our-image"/>
                </Col>
            </Row>
    );
}

class Home extends Component {

    render(){

        return (
            <>
            <HomeHeader />
            <Container fluid className="p-0">
                <RenderJumbotron />
            </Container>
            <div className="container-fluid p-0"> 
                <div className="row">
                    <video className="ibank-video" controls preload="auto" muted loop>   
                        <source src="../assets/images/home_page_video_720p.mp4"
                        type="video/mp4"/>
                        Sorry, your browser doesn't support videos.
                    </video>
               </div>
            </div>
            <Container style={{marginTop: "30px"}}>
                <Row>
                    <RenderCards />
                    {/* <RenderTable/> */}
                </Row>
            </Container>
            <Container className="ml-0 mr-0 pl-0 pr-0 d-flex justify-content-center" 
            style={{maxWidth: "100%", 
            marginTop: "30px"}}>
                <RenderAbout />
            </Container>
            <Container className="d-flex align-content-center">
                <RenderFacts />
            </Container>
            <Container className="d-flex align-content-center">
                    <RenderCaseStudies />
            </Container>
            <Container fluid className="d-flex align-items-center" style={{backgroundColor: "#C0C0C0"}}>
                <Row style={{marginTop : '40px'}}>
                    <div className="d-flex justify-content-center mt-3">
                        <p className="pages-end mb-0">Let's work together :</p>
                    </div>
                    <div className="d-flex justify-content-center text-center">
                        <p className="pages-end-para">Reach out to us to chat about how we can collaborate and deliver value.</p>
                    </div>
                    <div className="d-flex justify-content-center text-center mb-5 mt-3">
                        <Button variant="outline" type="submit" href="/contact" className="contact-btn rounded-3">
                            Contact Us
                        </Button>
                    </div>
                </Row>
            </Container>
            </>
        );
    }
}

export default Home;

