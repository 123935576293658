import axios from 'axios';

const url = 'https://thecqtech.com/api';

export const fetchJobs = () => axios.get(`${url}/jobs`);
export const createJob = (newJob) => axios.post(`${url}/jobs`, newJob);
export const updateJob = (id, updatedJob) => axios.patch(`${url}/jobs/${id}`, updatedJob);
export const deleteJob = (id) => axios.delete(`${url}/jobs/${id}`);

export const fetchApplicants = () => axios.get(`${url}/applicants`);
export const createApplicant = (newApplicant) => axios.post(`${url}/applicants`, newApplicant);
export const updateApplicant = (id, updatedApplicant) => 
                            axios.patch(`${url}/applicants/${id}`, updatedApplicant);
export const deleteApplicant = (id) => axios.delete(`${url}/applicants/${id}`);
