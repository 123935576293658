export const FETCH_ALL_JOBS         = 'FETCH_ALL_JOBS';
export const CREATE_JOB             = 'CREATE_JOB';
export const UPDATE_JOB             = 'UPDATE_JOB';
export const DELETE_JOB             = 'DELETE_JOB';
export const FETCH_ALL_APPLICANTS   = 'FETCH_ALL_APPLICANTS';
export const CREATE_APPLICANT       = 'CREATE_APPLICANT';
export const DELETE_APPLICANT       = 'DELETE_APPLICANT';
export const LOGIN_REQUEST          = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS          = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE          = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST         = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS         = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE         = 'LOGOUT_FAILURE';
