import * as ActionTypes from './ActionTypes';
import * as api from '../api/index.js';

export const createJob = (job) => async (dispatch) => {
    try {
        const { data } = await api.createJob(job);

        dispatch({ type: ActionTypes.CREATE_JOB, payload: data })
    } catch (error) {
        console.log(error.message);
    }
}

export const fetchJobs = () => async (dispatch) => {
    try {
        const { data } = await api.fetchJobs();

        dispatch({ type: ActionTypes.FETCH_ALL_JOBS, payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

export const updateJob = (id, job) => async (dispatch) => {
    try {
        const { data } = await api.updateJob(id, job);

        dispatch({ type: ActionTypes.UPDATE_JOB, payload: data });
    } catch (error) {
        console.log(error.message);   
    }
}

export const deleteJob = (id) => async (dispatch) => {
    try {
        await api.deleteJob(id);

        dispatch({ type: ActionTypes.DELETE_JOB, payload: id });
    } catch (error) {
        
    }
}

export const createApplicant = (applicant) => async (dispatch) => {
    try {
        const { data } = await api.createApplicant(applicant);

        dispatch({ type: ActionTypes.CREATE_APPLICANT, payload: data })
    } catch (error) {
        console.log(error.message);
    }
}

export const fetchApplicants = () => async (dispatch) => {
    try {
        const { data } = await api.fetchApplicants();

        dispatch({ type: ActionTypes.FETCH_ALL_APPLICANTS, payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

export const deleteApplicant = (id) => async (dispatch) => {
    try {
        await api.deleteApplicant(id);

        dispatch({ type: ActionTypes.DELETE_APPLICANT, payload: id });
    } catch (error) {
        console.log(error.message);        
    }
}